/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function CampfireModel(props) {
  const { nodes, materials } = useGLTF("/gltfs/Campfire-transformed.glb");
  return (
    <group {...props} dispose={null}>
      <group position={[-1.96, 1.69, 1.8]}>
        <group position={[1.59, -1.32, 3.29]}>
          <directionalLight intensity={3.14} decay={2} color="#a3ebff" position={[0.92, 2.45, 1.14]} rotation={[-1.7, -0.41, -0.06]} />
          <pointLight intensity={15.71} decay={2} distance={2.5} color="#fff700" position={[0.54, 0.04, -0.65]} rotation={[-Math.PI, 0, -Math.PI]} />
          <pointLight intensity={15.71} decay={2} distance={2.5} color="#fff700" position={[0.47, 0.5, -0.72]} rotation={[-Math.PI, 0, -Math.PI]} />
          <pointLight intensity={15.71} decay={2} distance={2} color="#ffa218" position={[2.21, 0.88, 0.31]} rotation={[-Math.PI, 0, -Math.PI]} />
        </group>
        <group position={[1.44, 0.24, 14.13]} rotation={[-0.16, 0.04, 0.12]} scale={[2.47, 1.67, 2.47]}>
          <mesh geometry={nodes.tree_pine.geometry} material={materials.Trees1} position={[-0.03, 0.28, -4.14]} rotation={[0.15, -0.05, -0.11]} scale={[0.91, 1.02, 0.92]} />
          <mesh geometry={nodes["tree_pine_(2)"].geometry} material={materials.Trees1} position={[-0.26, 0.43, -4.65]} rotation={[0.04, -0.92, -0.12]} scale={[0.92, 0.9, 0.91]} />
          <mesh geometry={nodes["tree_pine_(3)"].geometry} material={materials.Trees1} position={[1.44, 0.24, -5.22]} rotation={[-0.13, -0.94, -0.2]} scale={[0.91, 0.86, 0.95]} />
          <mesh geometry={nodes["tree_pine_(5)"].geometry} material={materials.Trees1} position={[0.23, 0.22, -5.31]} rotation={[0.16, -0.74, -0.08]} scale={[0.92, 0.85, 0.96]} />
          <mesh geometry={nodes["tree_pine_(6)"].geometry} material={materials.Trees1} position={[-0.01, 0.08, -5.27]} rotation={[-0.39, -1.26, -0.61]} scale={[0.93, 0.86, 0.96]} />
          <mesh geometry={nodes["tree_pine_(7)"].geometry} material={materials.Trees1} position={[-0.79, 0.51, -4.44]} rotation={[-2.72, -1.2, -2.8]} scale={[0.73, 0.67, 0.75]} />
          <mesh geometry={nodes["tree_pine_(1)"].geometry} material={materials.Trees2} position={[-0.28, 0.32, -4.15]} rotation={[0.02, -0.42, -0.06]} scale={0.91} />
          <mesh geometry={nodes["tree_pine_(4)"].geometry} material={materials.Trees2} position={[1.56, -0.05, -4.99]} rotation={[0.16, -0.74, -0.08]} scale={[0.92, 0.85, 0.96]} />
        </group>
        <group position={[3.7, -2.73, 3.85]} scale={1.42}>
          <mesh geometry={nodes.fire_high_poly.geometry} material={materials.FireYellowHighpoly} position={[-1.25, 0.95, -0.95]} scale={0.81} />
          <mesh geometry={nodes.stones.geometry} material={materials.Rocks} position={[-0.97, 1.01, -0.49]} scale={1.13} />
          <mesh geometry={nodes.logs2.geometry} material={materials.Logs} position={[-1.5, 1.07, -1.04]} />
        </group>
        <group position={[2.42, -0.81, 5.49]} scale={2.5}>
          <mesh geometry={nodes.Torch.geometry} material={materials.Logs} position={[0.59, 0.01, -1.95]} rotation={[0, 0, 0.12]} scale={0.4}>
            <pointLight intensity={12.57} decay={2} distance={1.5} color="#fff700" position={[0, 0.04, -0.01]} rotation={[-Math.PI, 0, -3.02]} />
            <mesh geometry={nodes.fire_high_poly_2.geometry} material={materials.FireYellowHighpoly} position={[0, -0.02, -0.01]} rotation={[0.17, -0.21, -0.09]} scale={0.24} />
          </mesh>
          <mesh geometry={nodes["Torch_(1)"].geometry} material={materials.Logs} position={[1, 0.01, -0.61]} rotation={[0, 0, 0.12]} scale={0.4}>
            <pointLight intensity={12.57} decay={2} distance={1.5} color="#fff700" position={[0, 0.04, -0.01]} rotation={[-Math.PI, 0, -3.02]} />
            <mesh geometry={nodes.fire_high_poly_3.geometry} material={materials.FireYellowHighpoly} position={[0, -0.02, -0.01]} rotation={[0.17, -0.21, -0.09]} scale={0.24} />
          </mesh>
          <mesh geometry={nodes["Torch_(2)"].geometry} material={materials.Logs} position={[-0.49, 0.01, -1.88]} rotation={[0, 0, 0.12]} scale={0.4}>
            <pointLight intensity={12.57} decay={2} distance={1.5} color="#fff700" position={[0, 0.04, -0.01]} rotation={[-Math.PI, 0, -3.02]} />
            <mesh geometry={nodes.fire_high_poly_4.geometry} material={materials.FireYellowHighpoly} position={[0, -0.02, -0.01]} rotation={[0.17, -0.21, -0.09]} scale={0.24} />
          </mesh>
          <mesh geometry={nodes["Torch_(3)"].geometry} material={materials.Logs} position={[-0.12, 0.01, -0.07]} rotation={[0, 0, 0.12]} scale={0.4}>
            <pointLight intensity={12.57} decay={2} distance={1.5} color="#fff700" position={[0, 0.04, -0.01]} rotation={[-Math.PI, 0, -3.02]} />
            <mesh geometry={nodes.fire_high_poly_5.geometry} material={materials.FireYellowHighpoly} position={[0, -0.02, -0.01]} rotation={[0.17, -0.21, -0.09]} scale={0.24} />
          </mesh>
          <mesh geometry={nodes["Torch_(4)"].geometry} material={materials.Logs} position={[-0.99, 0.01, -0.77]} rotation={[0, 0, 0.12]} scale={0.4}>
            <pointLight intensity={12.57} decay={2} distance={1.5} color="#fff700" position={[0, 0.04, -0.01]} rotation={[-Math.PI, 0, -3.02]} />
          </mesh>
        </group>
        <group position={[2.14, -1.81, 5.34]}>
          <mesh geometry={nodes["stones_(1)"].geometry} material={materials.Island} position={[2.13, 0.13, -3.12]} scale={2.31} />
          <mesh geometry={nodes["stones_(2)"].geometry} material={materials.Island} position={[-0.14, 0.24, -3.13]} rotation={[-0.06, -0.04, -0.1]} scale={[2.52, 3.37, 2.52]} />
          <mesh geometry={nodes["stones_(3)"].geometry} material={materials.Island} position={[0.59, -0.05, -2.44]} rotation={[3, -0.35, 3.01]} scale={[3.4, 5.3, 3.4]} />
          <mesh geometry={nodes["stones_(4)"].geometry} material={materials.Island} position={[-1.37, -0.08, -1.7]} rotation={[2.94, -0.35, -3.08]} scale={[3.4, 5.3, 3.4]} />
          <mesh geometry={nodes["stones_(5)"].geometry} material={materials.Island} position={[-2.75, 0.22, -2.32]} rotation={[3.09, -0.32, -2.77]} scale={[3.4, 4.94, 3.4]} />
          <mesh geometry={nodes["stones_(6)"].geometry} material={materials.Island} position={[2.65, 0.48, -2.21]} rotation={[-0.07, 0.93, 0.1]} scale={[4.69, 3, 4.69]} />
          <mesh geometry={nodes["stones_(7)"].geometry} material={materials.Island} position={[2, 0.32, -3.39]} rotation={[0.03, 0.74, 0.01]} scale={[3.97, 3, 3.2]} />
          <mesh geometry={nodes.island.geometry} material={materials.Island} position={[0.06, 0.49, -2.3]} scale={1.86} />
        </group>
        <group position={[3.81, -2.25, 3.83]} scale={0.94}>
          <mesh geometry={nodes.shelter.geometry} material={materials.Shelter} position={[-0.13, 1.78, -0.26]} rotation={[0.04, 0, -0.04]} scale={[0.87, 0.88, 0.87]} />
          <mesh geometry={nodes.shelter_pegs.geometry} material={materials.Logs} position={[-0.23, 1.78, -0.23]} scale={0.87} />
          <mesh geometry={nodes.shelter_wire.geometry} material={materials.Shelter} position={[-0.19, 1.49, -0.88]} rotation={[-0.64, -0.44, -0.36]} scale={[0.87, 0.94, 0.81]} />
          <mesh geometry={nodes["shelter_wire_(1)"].geometry} material={materials.Shelter} position={[-0.75, 1.45, -0.21]} rotation={[-2.31, 0.63, 2.48]} scale={[0.87, 0.89, 0.85]} />
          <mesh geometry={nodes["shelter_wire_(2)"].geometry} material={materials.Shelter} position={[-0.02, 1.4, 0.31]} rotation={[-2.22, 0.58, 2.4]} scale={[0.87, 0.9, 0.78]} />
          <mesh geometry={nodes["shelter_wire_(3)"].geometry} material={materials.Shelter} position={[0.51, 1.45, -0.18]} rotation={[-0.85, -0.64, 0.03]} scale={[0.87, 1.29, 0.73]} />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/gltfs/Campfire-transformed.glb");
