import * as THREE from "three";
import React, { Suspense, useState, useEffect } from "react";
import { Stats } from "@react-three/drei";
import { extend, useThree, useFrame, Canvas } from "@react-three/fiber";
import { motion, MotionCanvas, LayoutCamera } from "framer-motion-3d";
import { CampfireModel } from "../motion/CampfireModel";

extend(THREE);

const InteractiveGettingStarted = ({ isFullscreen, position, transitionFrom, transitionTo, waitBeforeShow = 2000 }) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);
    return () => clearTimeout(timer);
  }, [waitBeforeShow]);

  return isShown ? (
    <>
      <Suspense fallback={null}>
        <MotionCanvas>
          <LayoutCamera position={[10, 5, 5]} initial={false} animate={isFullscreen ? transitionFrom : transitionTo} transition={{ duration: 2 }} />
          <Lights isFullscreen={isFullscreen} />
          <group position={position}>
            <CampfireModel />
          </group>
        </MotionCanvas>
      </Suspense>
    </>
  ) : null;
};

const Lights = ({ isFullscreen }) => {
  const three = useThree();
  useFrame(() => {
    three.camera.lookAt(0, 0, 0);
  });
  return (
    <>
      {/* <Stats /> */}
      <ambientLight intensity={0.2} />
      <pointLight position={[-10, -10, 10]} intensity={2} color="#ff20f0" />
      <pointLight position={[0, 0.5, -1]} distance={1} intensity={2} color="#e4be00" />
      <motion.directionalLight castShadow intensity={1.5} shadow-mapSize-width={1024} shadow-mapSize-height={1024} shadow-camera-far={20} shadow-camera-left={-10} shadow-camera-right={10} shadow-camera-top={10} shadow-camera-bottom={-10} initial={false} animate={isFullscreen ? { x: 0, y: 8, z: 5 } : { x: 4, y: 3, z: 3 }} />
    </>
  );
};

export default InteractiveGettingStarted;
